import { useState, useRef, useEffect} from 'react';

import { NumericFormat} from 'react-number-format';


const DistanceCell = ({ value, onChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(null);
    const [lastChangedValue, setLastChangedValue] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        setInputValue(value || null);
        setLastChangedValue(value || null);
    }, [value]);

    const handleClick = () => {
        if (!isEditing) {
            setIsEditing(true);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (inputValue !== lastChangedValue && validateInput(inputValue)) {
            onChange(inputValue);
            setLastChangedValue(inputValue);
        }
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            inputRef.current.blur();
        }
    };

    const validateInput = (input) => {
        const regex = /^[0-9]+(\.[0-9]+)?$/;
        return regex.test(input);
    };

    return (
        <NumericFormat
            getInputRef={inputRef}
            value={inputValue}
            decimalScale={3}
            onClick={handleClick}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder='km'
            style={{
                width: "100%",
                resize: "none",
                padding: "0",
                boxSizing: "border-box",
                textAlign: "center"
            }}
        />
    );
};

export default DistanceCell;
