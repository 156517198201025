import { useState } from "react";
import WorkoutDataTable from "./WorkoutDataTable/WorkoutDataTable";
import TextCell from "./TextCell/TextCell";
import { ButtonGroup, Button, Modal, Form } from "react-bootstrap";


const WorkoutRow = ({ day, workouts, workoutTypes, workoutSubtypes, updateWorkout, deleteWorkout, createWorkout}) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedWorkoutType, setSelectedWorkoutType] = useState('');
    const [selectedWorkoutSubtype, setSelectedWorkoutSubtype] = useState('');
    const [selectedWorkout, setSelectedWorkout] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleDeleteClick = (workout) => {
        setSelectedWorkout(workout);
        setShowDeleteModal(true);
    };

    const handleAddWorkoutClick = () => {
        setShowAddModal(true);
    };

    const confirmDelete = () => {
        if (selectedWorkout) {
            deleteWorkout(selectedWorkout.id, selectedWorkout.workout_type);
            setShowDeleteModal(false);
        }
    };

    const handleCreateWorkout = () => {
        if (!selectedWorkoutType || !selectedWorkoutSubtype) {
          return;  // Don't proceed if type or subtype are not selected
        }

        setLoading(true);

        // Create the workout via API
        createWorkout({
            day: day,
            workout_type: selectedWorkoutType,
            workout_subtype: selectedWorkoutSubtype
        })
        .then((newWorkout) => {
            setShowAddModal(false);
            setLoading(false);
        })
        .catch((err) => {
            console.error(err);
            setLoading(false);
        })
    };

    const getWorkoutKey = (workout) => {
        return `${workout.workout_type}:${workout.id}`;
    };

    const sortedWorkouts = workouts.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    return (
    <>
        {sortedWorkouts.length > 0 ? (
            sortedWorkouts.map((workout, index) => (
                <tr key={getWorkoutKey(workout)}>
                    {index === 0 && <td rowSpan={sortedWorkouts.length}>{day.format('DD.MM')}</td>}
                    <td style={{padding: "0"}}>
                        <TextCell
                            value={workout.body || ''}
                            onChange={(newValue) =>
                                updateWorkout({
                                    workout_id: workout.id,
                                    workout_type: workout.workout_type,
                                    data: { body: newValue }
                                })
                            }
                        />
                    </td>
                    <td>
                        <WorkoutDataTable
                            workout={workout}
                            updateWorkout={updateWorkout}
                        />
                    </td>
                    <td style={{padding: "0"}}>
                        <TextCell
                            value={workout.result?.notes || ''}
                            onChange={(newValue) =>
                                updateWorkout({
                                    workout_id: workout.id,
                                    workout_type: workout.workout_type,
                                    data: { result: { notes: newValue } }
                                })
                            }
                        />
                    </td>
                    <td>
                        <ButtonGroup vertical>
                        <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteClick(workout)}
                        >
                            -
                        </Button>

                        {(index + 1) === sortedWorkouts.length && (
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={() => handleAddWorkoutClick()}
                            >
                                +
                            </Button>
                        )}
                        </ButtonGroup>
                    </td>
                </tr>
            ))
        ) : (
            <tr>
                <td>{day.format('DD.MM')}</td>
                <td colSpan="3">N/A</td>
                <td>
                    <ButtonGroup vertical>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleAddWorkoutClick()}
                        >
                            +
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        )}

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this workout?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Workout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formWorkoutType">
                        <Form.Label>Workout Type</Form.Label>
                        <Form.Control as="select" value={selectedWorkoutType} onChange={(e) => setSelectedWorkoutType(e.target.value)}>
                            <option value="">Select Workout Type</option>
                            {workoutTypes.map((type) => (
                                <option key={type.value} value={type.value}>
                                    {type.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formWorkoutSubtype">
                        <Form.Label>Workout Subtype</Form.Label>
                        <Form.Control as="select" value={selectedWorkoutSubtype} onChange={(e) => setSelectedWorkoutSubtype(e.target.value)} disabled={!selectedWorkoutType}>
                            <option value="">Select Workout Subtype</option>
                            {workoutSubtypes?.filter((subtype) => subtype.workout_type === selectedWorkoutType)
                            .map((subtype) => (
                                <option key={subtype.id} value={subtype.id}>
                                {subtype.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleCreateWorkout} disabled={loading}>
                    {loading ? 'Creating...' : 'Create Workout'}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
)};

export default WorkoutRow;
