import DurationCell from "./DurationCell/DurationCell";
import PaceCell from "./PaceCell/PaceCell";
import DistanceCell from "./DistanceCell/DistanceCell";
import HeartRateCell from "./HeartRateCell/HeartRateCell";
import { Table } from 'react-bootstrap';
import { Stopwatch, GeoAlt, Speedometer, Activity } from 'react-bootstrap-icons'
import { useState } from "react";


const durationToSeconds = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

const secondsToDuration = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return [
        String(hours).padStart(2, "0"),
        String(minutes).padStart(2, "0"),
        String(seconds).padStart(2, "0"),
    ].join(":");
};

const calculatePace = (distance, duration) => {
    if (distance === "0") return null;
    const total_seconds = durationToSeconds(duration);
    const seconds_per_km = total_seconds / distance;
    return secondsToDuration(seconds_per_km);
};

const calculateDuration = (distance, pace) => {
    const seconds_per_km = durationToSeconds(pace);
    return secondsToDuration(distance * seconds_per_km);
};


const WorkoutDataTable = ({ workout, updateWorkout}) => {
    const { id, workout_type, result } = workout;
    const [plannedDuration, setPlannedDuration] = useState(workout.duration || null);
    const [plannedDistance, setPlannedDistance] = useState(workout.distance_km || null);
    const [plannedPace, setPlannedPace] = useState(workout.pace_min_per_km || null);
    const [plannedHeartRate, setPlannedHeartRate] = useState(workout.heart_rate || null);
    const [actualDuration, setActualDuration] = useState(result?.duration || null);
    const [actualDistance, setActualDistance] = useState(result?.distance_km || null);
    const [actualPace, setActualPace] = useState(result?.pace_min_per_km || null);
    const [actualHeartRate, setActualHeartRate] = useState(result?.heart_rate || null);

    const handlePlannedChange = (field, value) => {
        let updatedDuration = plannedDuration;
        let updatedDistance = plannedDistance;
        let updatedPace = plannedPace;
        let updatedHeartRate = plannedHeartRate;

        if (workout_type === "running") {
            if (field === "duration") {
                updatedDuration = value;

                if (updatedDistance && updatedDuration) {
                    updatedPace = calculatePace(updatedDistance, updatedDuration);
                }
            } else if (field === "distance_km") {
                updatedDistance = value;

                if (updatedDistance && updatedDuration) {
                    updatedPace = calculatePace(updatedDistance, updatedDuration);
                }
            } else if (field === "pace_min_per_km") {
                updatedPace = value;

                if (updatedDistance && updatedPace) {
                    updatedDuration = calculateDuration(updatedDistance, updatedPace);
                }
            } else if (field === "heart_rate") {
                updatedHeartRate = value;
            }

            setPlannedDuration(updatedDuration);
            setPlannedDistance(updatedDistance);
            setPlannedPace(updatedPace);
            setPlannedHeartRate(updatedHeartRate);

            updateWorkout({
                workout_id: id,
                workout_type: workout_type,
                data: {
                    duration: updatedDuration,
                    distance_km: updatedDistance,
                    pace_min_per_km: updatedPace,
                    heart_rate: updatedHeartRate,
                }
            });

        } else if (workout_type === "cycling") {
            if (field === "duration") {
                updatedDuration = value;
            } else if (field === "distance_km") {
                updatedDistance = value;
            } else if (field === "heart_rate") {
                updatedHeartRate = value;
            }

            setPlannedDuration(updatedDuration);
            setPlannedDistance(updatedDistance);
            setPlannedHeartRate(updatedHeartRate);

            updateWorkout({
                workout_id: id,
                workout_type: workout_type,
                data: {
                    duration: updatedDuration,
                    distance_km: updatedDistance,
                    heart_rate: updatedHeartRate,
                }
            });
        } else {
            updatedDuration = value;
            setPlannedDuration(updatedDuration);

            updateWorkout({
                workout_id: id,
                workout_type: workout_type,
                data: {
                    duration: updatedDuration,
                }
            });
        }
    };

    const handleActualChange = (field, value) => {
        let updatedDuration = actualDuration;
        let updatedDistance = actualDistance;
        let updatedPace = actualPace;
        let updatedHeartRate = actualHeartRate;

        if (workout_type === "running") {
            if (field === "duration") {
                updatedDuration = value;

                if (updatedDistance && updatedDuration) {
                    updatedPace = calculatePace(updatedDistance, updatedDuration);
                }
            } else if (field === "distance_km") {
                updatedDistance = value;

                if (updatedDistance && updatedDuration) {
                    updatedPace = calculatePace(updatedDistance, updatedDuration);
                }
            } else if (field === "pace_min_per_km") {
                updatedPace = value;

                if (updatedDistance && updatedPace) {
                    updatedDuration = calculateDuration(updatedDistance, updatedPace);
                }
            } else if (field === "heart_rate") {
                updatedHeartRate = value;
            }

            setActualDuration(updatedDuration);
            setActualDistance(updatedDistance);
            setActualPace(updatedPace);
            setActualHeartRate(updatedHeartRate);

            updateWorkout({
                workout_id: id,
                workout_type: workout_type,
                data: {
                    result: {
                        duration: updatedDuration,
                        distance_km: updatedDistance,
                        pace_min_per_km: updatedPace,
                        heart_rate: updatedHeartRate,
                    }
                }
            });
        } else if (workout_type === "cycling") {
            if (field === "duration") {
                updatedDuration = value;
            } else if (field === "distance_km") {
                updatedDistance = value;
            } else if (field === "heart_rate") {
                updatedHeartRate = value;
            }

            setActualDuration(updatedDuration);
            setActualDistance(updatedDistance);
            setActualHeartRate(updatedHeartRate);

            updateWorkout({
                workout_id: id,
                workout_type: workout_type,
                data: {
                    result: {
                        duration: updatedDuration,
                        distance_km: updatedDistance,
                        heart_rate: updatedHeartRate,
                    }
                }
            });
        } else {
            updatedDuration = value;
            setActualDuration(updatedDuration);

            updateWorkout({
                workout_id: id,
                workout_type: workout_type,
                data: {
                    result: {
                        duration: updatedDuration,
                    }
                }
            });
        }
    };

    const getWorkoutKey = (workout, field) => {
        return `${workout.workout_type}:${workout.id}:${field}`;
    };

    const renderRow = (Icon, cells, key) => (
        <tr key={key}>
            <td style={{ padding: "0" }}>
                <Icon />
            </td>
            {cells.map((cell, index) => (
                <td key={index} style={{padding: "0"}}>{cell}</td>
            ))}
        </tr>
    );

    return (
        <Table responsive size="sm">
            <tbody>
                {renderRow(Stopwatch, [
                    <DurationCell
                        value={plannedDuration}
                        onChange={(newValue) => handlePlannedChange("duration", newValue)}
                    />,
                    <DurationCell
                        value={actualDuration}
                        onChange={(newValue) => handleActualChange("duration", newValue)}
                    />,
                ], getWorkoutKey(workout, 'duration'))}
                {workout_type === "running" && (
                    <>
                        {renderRow(GeoAlt, [
                            <DistanceCell
                                value={plannedDistance}
                                onChange={(newValue) => handlePlannedChange("distance_km", newValue)}
                            />,
                            <DistanceCell
                                value={actualDistance}
                                onChange={(newValue) => handleActualChange("distance_km", newValue)}
                            />,
                        ], getWorkoutKey(workout, 'distance_km'))}
                        {renderRow(Speedometer, [
                            <PaceCell
                                value={plannedPace}
                                onChange={(newValue) => handlePlannedChange("pace_min_per_km", newValue)}
                            />,
                            <PaceCell
                                value={actualPace}
                                onChange={(newValue) => handleActualChange("pace_min_per_km", newValue)}
                            />,
                        ], getWorkoutKey(workout, 'pace_min_per_km'))}
                        {renderRow(Activity, [
                            <HeartRateCell
                                value={plannedHeartRate}
                                onChange={(newValue) => handlePlannedChange("heart_rate", newValue)}
                            />,
                            <HeartRateCell
                                value={actualHeartRate}
                                onChange={(newValue) => handleActualChange("heart_rate", newValue)}
                            />,
                        ], getWorkoutKey(workout, 'heart_rate'))}
                    </>
                )}
                {workout_type === "cycling" && (
                    <>
                        {renderRow(GeoAlt, [
                            <DistanceCell
                                value={plannedDistance}
                                onChange={(newValue) => handlePlannedChange("distance_km", newValue)}
                            />,
                            <DistanceCell
                                value={actualDistance}
                                onChange={(newValue) => handleActualChange("distance_km", newValue)}
                            />,
                        ], getWorkoutKey(workout, 'distance_km'))}
                        {renderRow(Activity, [
                            <HeartRateCell
                                value={plannedHeartRate}
                                onChange={(newValue) => handlePlannedChange("heart_rate", newValue)}
                            />,
                            <HeartRateCell
                                value={actualHeartRate}
                                onChange={(newValue) => handleActualChange("heart_rate", newValue)}
                            />,
                        ], getWorkoutKey(workout, 'heart_rate'))}
                    </>
                )}
            </tbody>
        </Table>
    );
};

export default WorkoutDataTable;
