import { useState, useEffect, useRef } from 'react';
import { PatternFormat } from 'react-number-format';


const PaceCell = ({ value, onChange }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(null);
    const [lastChangedValue, setLastChangedValue] = useState(null);
    const inputRef = useRef(null);

    const formatValue = (value) => {
        if (value) {
            const [hours, minutes, seconds] = value.split(":").map(Number);
            const totalMinutes = (hours || 0) * 60 + (minutes || 0);
            return `${String(totalMinutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        } else {
            return null;
        }
    };

    useEffect(() => {
        setInputValue(formatValue(value));
        setLastChangedValue(formatValue(value));
    }, [value]);

    const handleClick = () => {
        if (!isEditing) {
            setIsEditing(true);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (inputValue !== lastChangedValue && validateInput(inputValue)) {
            if (inputValue === '') {
                onChange("00:00:00");
            } else {
                const [minutes, seconds] = inputValue.split(":").map(Number);
                const hours = Math.floor(minutes / 60);
                const remainingMinutes = minutes % 60;
                const formattedValue = `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
                onChange(formattedValue);
            }

            setLastChangedValue(inputValue);
        }
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            inputRef.current.blur();
        }
    };

    const validateInput = (input) => {
        if (input === '') return true;
        const regex = /^([0-9]{1,2}):([0-9]{2})$/;
        return regex.test(input);
    };

    return (
        <PatternFormat
            getInputRef={inputRef}
            format="##:##"
            mask=""
            value={inputValue}
            onClick={handleClick}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder='mm:ss'
            style={{
                width: "100%",
                resize: "none",
                padding: "0",
                boxSizing: "border-box",
                textAlign: "center",
            }}
        />
    );
};

export default PaceCell;
