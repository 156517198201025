import React from 'react';

import { useUserContext } from '../contexts/UserContext.js';
import CoachDashboard from '../components/CoachDashboard/CoachDashboard.js';
import StudentJournal from '../components/StudentJournal/StudentJournal.js';

const HomePage = () => {
  const user_context = useUserContext();
  const user = user_context.getUser();


  if (user) {
    if (user.is_coach) {
      return (<div>
        <CoachDashboard />
      </div>);
    } else if (user.is_student) {
      return (<div>
        <StudentJournal student_id={user.id}/>
      </div>);
    }
  }

  return (<div></div>);
}

export default HomePage;
