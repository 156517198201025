import React, { createContext, useState, useContext } from "react";

import { isAccessTokenSet } from '../api/auth.js'

const CURRENT_USER = 'current_user';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const storedUser = (window.localStorage.getItem(CURRENT_USER));
  const [_user, _setUser] = useState((storedUser) ? JSON.parse(storedUser) : null);

  const isUserLoggedIn = () => {
    return isAccessTokenSet();
  }

  const getUser = () => {
    return _user;
  }

  const setUser = (data) => {
    _setUser(data);
    window.localStorage.setItem(CURRENT_USER, JSON.stringify(data));
  }

  return (
    <UserContext.Provider value={{ getUser, setUser, isUserLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
