import { authRequest } from './auth.js'

const getCurrentUser = () => {
  return authRequest.get('/api/v1/users/me/').then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=> {
    return Promise.reject(error)
  });
}

const createUserAsStudent = (data) => {
  return authRequest.post('/api/v1/users/students/', data).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=> {
    return Promise.reject(error)
  });
}


const updateCurrentUser = (data) => {
  return authRequest.patch('/api/v1/users/me/', data)
    .then(response=>{
      return Promise.resolve(response.data)
    }).catch((error)=> {
      return Promise.reject(error)
    });
}


const changeCurrentUserPassword = (data) => {
  return authRequest.post('/api/v1/users/me/change-password/', data).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=> {
    return Promise.reject(error)
  });
}

const getUsers = (tags = []) => {
  const params = tags.length ? { tags: tags.join(",") } : {};

  return authRequest.get('/api/v1/users/', { params }).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}

const getUser = (user_id) => {
  return authRequest.get(`/api/v1/users/${user_id}/`).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}

const getTags = () => {
  return authRequest.get('/api/v1/tags/').then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}


const removeUserTag = (user_id, tag_name) => {
  return authRequest.post(`/api/v1/users/${user_id}/tags/remove/`, {name: tag_name}).then(response=>{
    return Promise.resolve(response.data);
  }).catch((error) => {
    return Promise.reject(error);
  });
}

const addUserTag = (user_id, tag_name) => {
  return authRequest.post(`/api/v1/users/${user_id}/tags/add/`, {name: tag_name}).then(response=>{
    return Promise.resolve(response.data);
  }).catch((error) => {
    return Promise.reject(error);
  });
}

const getWorkouts = (user_id, date_start, date_end) => {
  const params = { date_start: date_start, date_end: date_end };
  return authRequest.get(`/api/v1/users/${user_id}/workouts/`, { params }).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}

const updateWorkout = ({workout_id, workout_type, data}) => {
  return authRequest.patch(`/api/v1/workout/${workout_type}/assignments/${workout_id}/`, data).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}

const removeWorkout = (workout_id, workout_type) => {
  return authRequest.delete(`/api/v1/workout/${workout_type}/assignments/${workout_id}/`).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}

const createWorkout = ({coach_id, student_id, workout_type, workout_subtype, date}) => {
  return authRequest.post(`/api/v1/workout/${workout_type}/assignments/`, {
    coach: coach_id,
    student: student_id,
    workout_type: workout_type,
    workout_subtype: workout_subtype,
    date: date
  }).then(response=>{
    return Promise.resolve(response.data)
  }).catch((error)=>{
    return Promise.reject(error)
  });
}

export {
  getCurrentUser,
  updateCurrentUser,
  createUserAsStudent,
  changeCurrentUserPassword,
  getUser,
  getUsers,
  getTags,
  removeUserTag,
  addUserTag,
  getWorkouts,
  updateWorkout,
  createWorkout,
  removeWorkout
}
