import React, { useState, useRef } from 'react';
import './UserInputTags.css';
import { Spinner } from 'react-bootstrap';

import { removeUserTag, addUserTag } from '../../api/rest';


const UserInputTags = ({user, onTagDeleted, onTagAdded}) => {
    const [tags, setTags] = useState(user.tags.map((tag) => tag.name));
    const [loading, setLoading] = useState(false);
    const tagInput = useRef()

    const removeTag = (index) => {
        setLoading(true);

        removeUserTag(user.id, tags[index]).then((data) => {
            setTags(tags.filter((_, i) => i !== index));

            if (data.tag_deleted) {
                onTagDeleted(tags[index]);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const inputKeyDown = (event) => {
        const val = event.target.value;

        if (event.key === 'Enter' && val) {
            if (tags.some((tag) => tag.toLowerCase() === val.toLowerCase())) {
                tagInput.current.value = '';
                return
            };

            setLoading(true);

            addUserTag(user.id, val).then((data) => {
                setTags([...tags, val]);
                onTagAdded(data);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    if (loading) return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    return (
        <div className="input-tag">
            <ul className="input-tag__tags">
                {tags.map((tag, i) => (
                    <li key={i}>
                        {tag}
                        <button type="button" onClick={() => removeTag(i)}>
                            ×
                        </button>
                    </li>
                ))}
                <li className="input-tag__tags__input">
                    <input
                        type="text"
                        onKeyDown={inputKeyDown}

                        ref={tagInput}
                    />
                </li>
            </ul>
        </div>
    );
};

export default UserInputTags;
