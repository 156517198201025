import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../contexts/UserContext.js';
import { Table, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { format } from "date-fns";

import { getTags, getUsers } from '../../api/rest.js';
import UserInputTags from '../UserInputTags/UserInputTags.js';
import Tags from '../Tags/Tags.js';


const CoachDashboard = () => {
    const user_context = useUserContext();
    const user = user_context.getUser();

    const [students, setStudents] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (user) {
            getTags().then((data) => {
                setTags(data);
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setLoading(false);
            });
        };
      }, [user]);

    useEffect(() => {
        if (user) {
            getUsers(selectedTags.map((tag) => tag.name)).then((data) => {
                setStudents(data);
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setLoading(false);
            });
        };
      }, [user, selectedTags]);

    const toggleTag = (tag) => {
        setSelectedTags((prevSelectedTags) =>
            prevSelectedTags.includes(tag)
            ? prevSelectedTags.filter((t) => t !== tag)
            : [...prevSelectedTags, tag]
        );
    };

    const onTagDeleted = (tag_name) => {
        setTags(tags.filter((tag) => tag.name !== tag_name));
        setSelectedTags(selectedTags.filter((tag) => tag.name !== tag_name));
    };

    const onTagAdded = (created_tag) => {
        if (!tags.some((tag) => tag.id === created_tag.id)) {
            setTags([...tags, created_tag]);
        }
    };

    const onStudentClick = (student) => {
        const url = `#students/${student.id}/journal`;
        window.open(url, "_blank");
    };

    if (loading) return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );

    if (error) return (<p>Error: {error}</p>);


    return (
        <Container fluid>
            <Row className="mb-3">
                <Col className="d-flex justify-content-end">
                    <Button href='#students/add'>Add student</Button>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} lg={2} className="order-lg-1" color='primary'>
                    <Tags tags={tags} selectedTags={selectedTags} onToogle={toggleTag}></Tags>
                </Col>
                <Col xs={12} lg={10} className="order-lg-2">
                    <Table responsive striped bordered hover>
                        <thead>
                        <tr>
                            <th style={{ width: '40%' }}>Full name</th>
                            <th style={{ width: '10%' }}>Birth date</th>
                            <th style={{ width: '20%' }}>Email</th>
                            <th style={{ width: '20%' }}>Phone number</th>
                            <th style={{ width: '10%' }}>Date Joined</th>
                        </tr>
                        </thead>
                        <tbody>
                        {students.map(student => (
                            <React.Fragment key={student.id}>
                            <tr style={{ cursor: "pointer" }}
                                onClick={() => {onStudentClick(student)}
                            }>
                                <td>{student.full_name}</td>
                                <td>{student.profile?.birth_date ? format(new Date(student.profile.birth_date), "dd.MM.yyyy") : ""}</td>
                                <td>{student.email || ""}</td>
                                <td>{student.phone_number}</td>
                                <td>{format(new Date(student.date_joined), "dd.MM.yyyy") || ""}</td>
                            </tr>
                            <tr>
                            <td colSpan="5">
                                <UserInputTags user={student} onTagDeleted={onTagDeleted} onTagAdded={onTagAdded}/>
                            </td>
                            </tr>
                            </React.Fragment>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default CoachDashboard;
