import { Table, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';

const durationToSeconds = (duration) => {
    if (!duration) return 0;
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

const secondsToDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const WorkoutSummary = ({ workouts, loadingWorkouts }) => {
    const [totalPlannedDuration, setTotalPlannedDuration] = useState('00:00:00');
    const [totalActualDuration, setTotalActualDuration] = useState('00:00:00');
    const [totalPlannedRunningDistance, setTotalPlannedRunningDistance] = useState(0);
    const [totalActualRunningDistance, setTotalActualRunningDistance] = useState(0);
    const [totalPlannedCyclingDistance, setTotalPlannedCyclingDistance] = useState(0);
    const [totalActualCyclingDistance, setTotalActualCyclingDistance] = useState(0);

    useEffect(() => {
        let totalPlannedDuration = 0;
        let totalActualDuration = 0;
        let totalPlannedRunningDistance = 0;
        let totalActualRunningDistance = 0;
        let totalPlannedCyclingDistance = 0;
        let totalActualCyclingDistance = 0;

        // Process workouts
        workouts.forEach((workout) => {
            // Calculate planned metrics
            totalPlannedDuration += durationToSeconds(workout.duration || '00:00:00');
            if (workout.workout_type === 'running') {
                totalPlannedRunningDistance += workout.distance_km || 0;
            } else if (workout.workout_type === 'cycling') {
                totalPlannedCyclingDistance += workout.distance_km || 0;
            }

            // Calculate actual metrics (if result exists)
            if (workout.result) {
                totalActualDuration += durationToSeconds(workout.result.duration || '00:00:00');
                if (workout.workout_type === 'running') {
                    totalActualRunningDistance += workout.result.distance_km || 0;
                } else if (workout.workout_type === 'cycling') {
                    totalActualCyclingDistance += workout.result.distance_km || 0;
                }
            }
        });

        setTotalPlannedDuration(secondsToDuration(totalPlannedDuration));
        setTotalActualDuration(secondsToDuration(totalActualDuration));
        setTotalPlannedRunningDistance(totalPlannedRunningDistance);
        setTotalActualRunningDistance(totalActualRunningDistance);
        setTotalPlannedCyclingDistance(totalPlannedCyclingDistance);
        setTotalActualCyclingDistance(totalActualCyclingDistance);
    }, [workouts]);

    return (
        <>
        {loadingWorkouts ? (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        ) : (
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total planned duration: {totalPlannedDuration}</td>
                    </tr>
                    <tr>
                        <td>Total actual duration: {totalActualDuration}</td>
                    </tr>
                    <tr>
                        <td>Total planned running distance: {totalPlannedRunningDistance} km</td>
                    </tr>
                    <tr>
                        <td>Total actual running distance: {totalActualRunningDistance} km</td>
                    </tr>
                    <tr>
                        <td>Total planned cycling distance: {totalPlannedCyclingDistance} km</td>
                    </tr>
                    <tr>
                        <td>Total actual cycling distance: {totalActualCyclingDistance} km</td>
                    </tr>
                </tbody>
            </Table>
        )}
        </>
    );
};

export default WorkoutSummary;
