import React from 'react';
import './Tags.css';

import { ListGroup } from 'react-bootstrap';

const Tags = ({tags, selectedTags, onToogle}) => {

    return (
        <div className="tags-container">
            <div className="tags-flex">
                {tags.map((tag, i) => (
                    <ListGroup.Item key={i} className={selectedTags.includes(tag) ? 'active' : ''} onClick={() => onToogle(tag)}>
                        {tag.name}
                    </ListGroup.Item>
                ))}
            </div>
        </div>
    );
};

export default Tags;
