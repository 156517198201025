import Card from 'react-bootstrap/Card';

const StudentCard = ({ student }) => {
    return (
        <Card>
            <Card.Header as="h5"> {student.full_name} </Card.Header>
            <Card.Body>
                <Card.Text>
                Fat burning HR - {student.profile?.fat_burning_hr || 'N/A'}<br/>
                Aerobic threshold HR - {student.profile?.aerobic_threshold_hr || 'N/A'}<br/>
                Anaerobic threshold HR - {student.profile?.anaerobic_threshold_hr || 'N/A'}<br/>
                VO2 Max HR - {student.profile?.vo2_max_hr || 'N/A'}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default StudentCard;
