import { useState, useEffect, useRef } from 'react';


const TextCell = ({ value, onChange, textAlign = "left" }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value || '');
    const [lastChangedValue, setLastChangedValue] = useState(value || '');
    const inputRef = useRef(null);

    const handleClick = () => {
        if (!isEditing) {
            setIsEditing(true);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (inputValue !== lastChangedValue) {
            onChange(inputValue);
            setLastChangedValue(inputValue);
        }
    };

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && e.shiftKey) {
            e.preventDefault();
            const cursorPosition = e.target.selectionStart;
            const newText = inputValue.slice(0, cursorPosition) + "\n" + inputValue.slice(cursorPosition);
            setInputValue(newText);
        }

        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            inputRef.current.blur();
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = "auto";
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [inputValue]);

    return (
        <textarea
            ref={inputRef}
            value={inputValue}
            onClick={handleClick}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder='N/A'
            style={{
                width: "100%",
                height: "auto",
                border: "none",
                resize: "none",
                overflow: "hidden",
                spellcheck: "true",
                padding: "4px",
                boxSizing: "border-box",
                wordWrap: "break-word",
                textAlign: textAlign,
            }}
        />
    );
};

export default TextCell;
