import axios from 'axios';

const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'


let tokenRequest = axios.create({
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    }
})

const loginUser = (phone_number, password) => {
  const loginBody = {phone_number: phone_number, password: password}

  return tokenRequest.post(`/api/v1/auth/token/`, loginBody)
    .then((response)=> {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
      return Promise.resolve(response.data);
    }).catch((error)=>{
      return Promise.reject(error);
    });
}

const refreshToken = () => {
  const refreshBody = {"refresh": window.localStorage.getItem(REFRESH_TOKEN)}
  return tokenRequest.post(`/api/v1/auth/token/refresh/`, refreshBody)
    .then((response)=> {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      return Promise.resolve(response.data);
    }).catch((error)=>{
      return Promise.reject(error);
    });
}

const isAuthenticationError = (status) => {
  return status === 401;
}

const authRequest = axios.create({
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json',
    }
});
authRequest.interceptors.request.use(function(config) {
  config.headers['Authorization'] = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`
  return config;
}, function (error) {
  return Promise.reject(error);
});
authRequest.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  (error) => { //handle the request
    return errorInterceptor(error)
  }
);

const errorInterceptor = (error) => {
  const originalRequest = error.config;
  const status = error.response.status;
  if (isAuthenticationError(status)) {
    return refreshToken().then((data)=> {
      const headerAuthorization = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`;
      authRequest.defaults.headers['Authorization'] = headerAuthorization;
      originalRequest.headers['Authorization'] = headerAuthorization;
      return authRequest(originalRequest)
    }).catch((error)=> {
      // if token refresh fails, logout the user to avoid potential security risks.
      const status = error.response.status;
      if (isAuthenticationError(status)) {
        logoutUser();
      }

      return Promise.reject(error)
    })
  }
  return Promise.reject(error)
}

const logoutUser = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  authRequest.defaults.headers['Authorization'] = "";
  window.location.href = "/"
}


const isAccessTokenSet = () => {
  return window.localStorage.getItem(ACCESS_TOKEN) !== null;
}

export { tokenRequest, loginUser, logoutUser, refreshToken, isAccessTokenSet, authRequest,
         errorInterceptor, ACCESS_TOKEN, REFRESH_TOKEN }
