import moment from 'moment';
import { Button, Form, Row, ButtonGroup } from 'react-bootstrap';


const WeekNavigation = ({ currentWeek, setCurrentWeek }) => {
    const changeWeek = (offset) => setCurrentWeek((prev) => prev.clone().add(offset, 'weeks'));

    return (
        <Row className="justify-content-md-end">
            <ButtonGroup>
                <Button variant="primary" onClick={() => changeWeek(-1)}>Previous Week</Button>
                <Button variant="secondary" onClick={() => setCurrentWeek(moment())}>Current Week</Button>
                <Button variant="primary" onClick={() => changeWeek(1)}>Next Week</Button>
            </ButtonGroup>
            <Form.Group controlId="specificDate">
                <Form.Label>Go to specific date:</Form.Label>
                <Form.Control
                    type="date"
                    onChange={(e) => setCurrentWeek(moment(e.target.value))}
                />
            </Form.Group>
        </Row>
    );
};

export default WeekNavigation;
