import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { logoutUser } from '../../api/auth.js'
import { useUserContext } from '../../contexts/UserContext.js';
import { ButtonGroup, Button } from 'react-bootstrap';


const Navigation = () => {

    const user_context = useUserContext();
    const user = user_context.getUser();

    const logout = (event) => {
        event.preventDefault();
        logoutUser();
        user_context.setUser(null);
    }

    return (
        <Navbar bg="dark" data-bs-theme="dark" className='mb-3'>
            <Container fluid>
                { user && user.organization ?
                    <Navbar.Brand href="/">{user.organization.name}</Navbar.Brand>
                    :
                    <Navbar.Brand href="/">Smart Sport</Navbar.Brand>
                }

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                { user ?
                    <>
                        <Nav className="justify-content-end">
                            <ButtonGroup>
                                { user.is_student &&
                                    <Button variant='secondary' href={`#students/${user.id}/journal`}>My journal</Button>
                                }

                                <Button variant="secondary" href='#profile'>My profile</Button>
                                <Button variant="primary" onClick={logout}>Logout</Button>
                            </ButtonGroup>
                        </Nav>
                    </>
                    :
                    <>
                        <Nav className="justify-content-end">
                            <Button variant="primary" href="#login">Login</Button>
                        </Nav>
                    </>
                }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
