import React from 'react';
import { useParams } from "react-router-dom";
import { useUserContext } from '../contexts/UserContext.js';
import StudentJournal from '../components/StudentJournal/StudentJournal.js';

const StudentJournalPage = () => {
  const { student_id } = useParams();
  const user_context = useUserContext();
  const user = user_context.getUser();

  if (user) {
    return (
        <div>
            <StudentJournal student_id={student_id}/>
        </div>
    );
}

  return (<div></div>);
}

export default StudentJournalPage;
