import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useUserContext } from '../../contexts/UserContext.js';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import {
    getUser as getUserApi,
    getWorkouts as getWorkoutsApi,
    updateWorkout as updateWorkoutApi,
    removeWorkout as removeWorkoutApi,
    createWorkout as createWorkoutApi
} from '../../api/rest.js';
import WeekNavigation from './WeekNavigation/WeekNavigation.js';
import WorkoutTable from './WorkoutTable/WorkoutTable.js';
import WorkoutSummary from './WorkoutSummary/WorkoutSummary.js';
import StudentCard from './StudentCard/StudentCard.js';


const StudentJournal = ({ student_id }) => {
    const user_context = useUserContext();
    const user = user_context.getUser();

    const [currentWeek, setCurrentWeek] = useState(moment());

    const daysOfWeek = useMemo(() => {
        const start = currentWeek.clone().startOf('isoWeek');
        return Array.from({ length: 7 }, (_, i) => start.clone().add(i, 'days'));
    }, [currentWeek]);

    const [student, setStudent] = useState(null);
    const [workouts, setWorkouts] = useState([]);
    const [workoutTypes, setWorkoutTypes] = useState([]);
    const [workoutSubtypes, setWorkoutSubtypes] = useState([]);
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingWorkouts, setLoadingWorkouts] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (user) {
            getUserApi(student_id).then((data) => {
                setStudent(data);
            }).catch(error => {
                setError(error.message);
            }).finally(() => {
                setLoadingPage(false);
            });
        };
    }, [user, student_id]);

    useEffect(() => {
        if (user) {
            const dateStart = currentWeek.clone().startOf('isoWeek').format('YYYY-MM-DD');
            const dateEnd = currentWeek.clone().endOf('isoWeek').format('YYYY-MM-DD');

            getWorkoutsApi(student_id, dateStart, dateEnd)
                .then((data) => {
                    setWorkouts(data.workouts || []);
                    setWorkoutTypes(data.workout_types || []);
                    setWorkoutSubtypes(data.workout_subtypes || []);
                })
                .catch((err) => setError(err.message))
                .finally(() => setLoadingWorkouts(false));
        }
    }, [user, student_id, currentWeek]);

    const deleteWorkout = (workout_id, workout_type) => {
        setLoadingWorkouts(true);

        const workoutToDelete = workouts.find(
            (workout) => (workout.id === workout_id && workout.workout_type === workout_type)
        );

        if (!workoutToDelete) {
            setError("Workout not found.");
            setLoadingWorkouts(false);
            return;
        }

        removeWorkoutApi(workoutToDelete.id, workoutToDelete.workout_type)
            .then((data) => {
                setWorkouts((prevWorkouts) => prevWorkouts.filter((workout) => (workout !== workoutToDelete)));
            })
            .catch((err) => setError(err.message))
            .finally(() => setLoadingWorkouts(false));
    };

    const createWorkout = ({day, workout_type, workout_subtype}) => {
        return createWorkoutApi({
            coach_id: user.id,
            student_id: student.id,
            workout_type: workout_type,
            workout_subtype: workout_subtype,
            date: day.format('YYYY-MM-DD')
        })
        .then((created_workout) => {
            setWorkouts([...workouts, created_workout]);
            return Promise.resolve(created_workout);
        })
        .catch((err) => {
            setError(err.message);
            return Promise.reject(err);
        })
        .finally(() => {})
    };

    const updateWorkout = ({workout_id, workout_type, data}) => {
        updateWorkoutApi({
            workout_id: workout_id,
            workout_type: workout_type,
            data: data
        }).then((data) => {
            setWorkouts((prevWorkouts) =>
                prevWorkouts.map((workout) =>
                    workout.id === workout_id && workout.workout_type === workout_type
                        ? { ...workout, ...data } // Merge the updated fields
                        : workout
                )
            );
        }).catch((err) => setError(err.message))
    }

    if (loadingPage) return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );
    if (error) return (<p>Error: {error}</p>);

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col xs={12} lg={9} className="order-lg-1" color='primary'>
                    <StudentCard student={student}/>
                </Col>
                <Col xs={12} lg={3} className="order-lg-2">
                    <WeekNavigation
                        currentWeek={currentWeek}
                        setCurrentWeek={setCurrentWeek}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} lg={10} className="order-lg-1" color='primary'>
                    <WorkoutTable
                        daysOfWeek={daysOfWeek}
                        workouts={workouts}
                        workoutTypes={workoutTypes}
                        workoutSubtypes={workoutSubtypes}
                        updateWorkout={updateWorkout}
                        deleteWorkout={deleteWorkout}
                        createWorkout={createWorkout}
                        loadingWorkouts={loadingWorkouts}
                    />
                </Col>
                <Col xs={12} lg={2} className="order-lg-2">
                    <WorkoutSummary
                        workouts={workouts}
                        loadingWorkouts={loadingWorkouts}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default StudentJournal;
